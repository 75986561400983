export const environment = {
    production: true,
    type: 'dev',

    // For Firebase JS SDK v7.20.0 and later, measurementId is optional
    firebaseConfig: {
        apiKey: 'AIzaSyAKFTrnqNlejb9tck8UeJ10st8HcBjibW8',
        authDomain: 'dev.simpleinjection.com',
        databaseURL: 'https://simpleinjection-dev-default-rtdb.firebaseio.com',
        projectId: 'simpleinjection-dev',
        storageBucket: 'simpleinjection-dev.appspot.com',
        messagingSenderId: '119350443329',
        appId: '1:119350443329:web:6e8bf9a485f446152835c0',
        measurementId: 'G-EPZT99ESEW',
    },
    firebaseFunctionsURL: 'https://us-central1-simpleinjection-dev.cloudfunctions.net',
};

<div class="header-container">
    <div class="logo-container">
        <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
            <nb-icon icon="menu-2-outline"></nb-icon>
        </a>
        <a class="logo" href="#" (click)="navigateHome()">
            <img src="assets/images/si-logo.png" class="logo-img">
        </a>
    </div>
    <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
        <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
    </nb-select>
</div>

<div class="header-container">
    <nb-actions size="small">

        <!-- <nb-action class="control-item">
                <nb-search type="rotate-layout"></nb-search>
            </nb-action> -->

        <nb-action class="control-item" [badgeText]="alarms" badgeStatus="danger" icon="bell-outline"
            badgePosition="top right" [link]="['/pages/devices', {alarms: true}]"></nb-action>
        <nb-action class="control-item" title="Get in touch" icon="email-outline"
            href="mailto:support@simpleinjection.com"></nb-action>

        <nb-action *ngIf="isSuperAdmin" class="control-item superAdmin" title="Super Admin Dashboard" link="dashboard"
            icon="shield">
        </nb-action>

        <nb-action class="user-action" [badgeText]="isAdmin ? '🛡️' : ''" badgeStatus="white"
            *nbIsGranted="['view', 'user']">
            <nb-user [nbContextMenu]="userMenu" [onlyPicture]="userPictureOnly" [name]="user?.displayName"
                [picture]="user?.photoURL">
            </nb-user>
        </nb-action>
    </nb-actions>
</div>
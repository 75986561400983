import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { Observable, of, combineLatest, BehaviorSubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ICompany } from '../shared/company.module';
import { IUser } from '../shared/user.module';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { IDevice } from '../shared/device.module';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root',
})
export class FirebaseService {

    companiesRef = 'companies/';
    usersRef = 'users/';
    devicesRef = 'devices/';
    deviceHistoryRef = 'deviceHistory/';
    companyHistoryRef = 'companyHistory/';
    metadataRef = 'metadata/';

    batteryLevelRef = '/battery_level';
    batteryVoltageRef = '/battery_voltage';
    solarPowerRef = '/solar_power';
    solarYieldRef = '/solar_yield';
    solarConsumptionRef = '/solar_consumption';

    tankLevelRef = '/tank_level';
    tankVolRef = '/tank_vol';
    injectionAmountRef = '/injection_amount';
    flowmeterRef = '/flowmeter_readings';
    controlSensorRef = '/controlSensor_readings';
    activityRef = '/activity';

    companiesList: AngularFireList<any>;
    usersList: AngularFireList<any>;
    devicesList: AngularFireList<any>;
    deviceHistoryList: AngularFireList<any>;

    _alarms: BehaviorSubject<number> = new BehaviorSubject(0);

    constructor(
        private db: AngularFireDatabase,
        private authService: AuthService,
        private router: Router,
        private http: HttpClient,
    ) {
        this.companiesList = db.list(this.companiesRef);
        this.usersList = db.list(this.usersRef);
        this.devicesList = db.list(this.devicesRef);
        this.deviceHistoryList = db.list(this.deviceHistoryRef);

        // Force logout for users missing from DB or with Expired Token
        this.getCurrentUserData().subscribe(currentUser => {
            if (currentUser === null) {
                // User is not in DB, logout
                this.router.navigateByUrl('auth/logout');
            } else {
                // User exists, subscribe to revokeTime and compare with authTime
                this.getRevokeTime(currentUser.key).subscribe(revokeTime => {
                    // Compare revokeTime to authTime
                    this.authService.getAuthTime().then(authTime => {
                        const isTokenValid = moment.utc(authTime).unix() > revokeTime;
                        // If Token is NOT valid, sign out
                        if (!isTokenValid) this.router.navigateByUrl('auth/logout');
                    });

                });
            }
        });
    }

    //#region "User"

    // Get current user data from Database
    getCurrentUserData(): Observable<any> {
        return this.authService.getAuthUser().pipe(
            switchMap(authUser => {
                if (authUser !== null && authUser !== undefined) {
                    // TODO: subscribe only once then emit the user from the service
                    return this.db.object(this.usersRef + authUser.uid).valueChanges().pipe(
                        map((user: IUser) => ({ key: authUser.uid, ...user })));
                } else {
                    // Always return an Observable when it is expected!
                    return of();
                }
            }));
    }

    // Get current user devices from Database
    getCurrentUserDevices(): Observable<IDevice[]> {
        return this.getCurrentUserData().pipe(
            switchMap(user => {
                const devicesObservables: Observable<IDevice>[] = [];
                if (user && user.devices) {
                    Object.keys(user.devices).forEach(deviceIMEI => {
                        devicesObservables.push(this.getDevice(deviceIMEI));
                    });
                    return combineLatest(devicesObservables);
                } else {
                    // If no devices, return Observable of empty array
                    return of([]);
                }
            }));
    }

    // Get Revoke time given user UID
    getRevokeTime(userUID: string) {
        return this.db.object(this.metadataRef + userUID + '/revokeTime').valueChanges();
    }

    // Get Battery History given device IMEI
    getDeviceBatteryVoltageHistory(deviceIMEI, dateRangeUnix, isMidNight) {

        let deviceHistoryRef = this.deviceHistoryRef + deviceIMEI;
        if (isMidNight) deviceHistoryRef += '/midnight';

        return this.db.list(deviceHistoryRef + this.batteryVoltageRef,
            ref => ref.orderByKey().startAt(dateRangeUnix.start).endAt(dateRangeUnix.end))
            .snapshotChanges()
            .pipe(map(items => items.map(i => ({ [i.payload.key]: i.payload.val() }))));
    }

    // Get Solar Yield given device IMEI
    getDeviceSolarYieldHistory(deviceIMEI, dateRangeUnix, isMidNight) {

        let deviceHistoryRef = this.deviceHistoryRef + deviceIMEI;
        if (isMidNight) deviceHistoryRef += '/midnight';

        return this.db.list(deviceHistoryRef + this.solarYieldRef,
            ref => ref.orderByKey().startAt(dateRangeUnix.start).endAt(dateRangeUnix.end))
            .snapshotChanges()
            .pipe(map(items => items.map(i => ({ [i.payload.key]: i.payload.val() }))));
    }

    // Get Battery History given device IMEI
    getDeviceBatteryLevelHistory(deviceIMEI, dateRangeUnix, isMidNight) {

        let deviceHistoryRef = this.deviceHistoryRef + deviceIMEI;
        if (isMidNight) deviceHistoryRef += '/midnight';

        return this.db.list(deviceHistoryRef + this.batteryLevelRef,
            ref => ref.orderByKey().startAt(dateRangeUnix.start).endAt(dateRangeUnix.end))
            .snapshotChanges()
            .pipe(map(items => items.map(i => ({ [i.payload.key]: i.payload.val() }))));
    }

    // Get Solar Consumption given device IMEI
    getDeviceSolarConsumptionHistory(deviceIMEI, dateRangeUnix, isMidNight) {

        let deviceHistoryRef = this.deviceHistoryRef + deviceIMEI;
        if (isMidNight) deviceHistoryRef += '/midnight';

        return this.db.list(deviceHistoryRef + this.solarConsumptionRef,
            ref => ref.orderByKey().startAt(dateRangeUnix.start).endAt(dateRangeUnix.end))
            .snapshotChanges()
            .pipe(map(items => items.map(i => ({ [i.payload.key]: i.payload.val() }))));
    }

    // Get Solar History given device IMEI
    getDeviceSolarPowerHistory(deviceIMEI, dateRangeUnix) {
        return this.db.list(this.deviceHistoryRef + deviceIMEI + this.solarPowerRef,
            ref => ref.orderByKey().startAt(dateRangeUnix.start).endAt(dateRangeUnix.end))
            .snapshotChanges()
            .pipe(map(items => items.map(i => ({ [i.payload.key]: i.payload.val() }))));
    }


    // Get Tank Level History given device IMEI
    getDeviceTankHistory(deviceIMEI, dateRangeUnix, isMidNight) {

        let historyRoot = this.deviceHistoryRef + deviceIMEI;
        if (isMidNight) historyRoot += '/midnight';

        return this.db.list(historyRoot + this.tankVolRef,
            ref => ref.orderByKey().startAt(dateRangeUnix.start).endAt(dateRangeUnix.end))
            .snapshotChanges()
            .pipe(map(items => items.map(i => ({ [i.payload.key]: i.payload.val() }))));
    }

    // Get Injection Amount History given device IMEI
    getDeviceInjectionHistory(deviceIMEI, dateRangeUnix, isMidNight) {

        let historyRoot = this.deviceHistoryRef + deviceIMEI;
        if (isMidNight) historyRoot += '/midnight';

        return this.db.list(historyRoot + this.injectionAmountRef,
            ref => ref.orderByKey().startAt(dateRangeUnix.start).endAt(dateRangeUnix.end))
            .snapshotChanges()
            .pipe(map(items => items.map(i => ({ [i.payload.key]: i.payload.val() }))));
    }

    // Get Flowmeter History given device IMEI
    getDeviceFlowmeterHistory(deviceIMEI, dateRangeUnix) {

        return this.db.list(this.deviceHistoryRef + deviceIMEI + this.flowmeterRef,
            ref => ref.orderByKey().startAt(dateRangeUnix.start).endAt(dateRangeUnix.end))
            .snapshotChanges()
            .pipe(map(items => items.map(i => ({ [i.payload.key]: i.payload.val() }))));
    }

    // Get Control Sensor History given device IMEI
    getDeviceControlSensorHistory(deviceIMEI, dateRangeUnix) {

        return this.db.list(this.deviceHistoryRef + deviceIMEI + this.controlSensorRef,
            ref => ref.orderByKey().startAt(dateRangeUnix.start).endAt(dateRangeUnix.end))
            .snapshotChanges()
            .pipe(map(items => items.map(i => ({ [i.payload.key]: i.payload.val() }))));
    }

    // Get Activity History given device IMEI
    getDeviceActivityHistory(deviceIMEI, dateRangeUnix) {
        return this.db.list(this.deviceHistoryRef + deviceIMEI + this.activityRef,
            ref => ref.orderByKey().startAt(dateRangeUnix.start).endAt(dateRangeUnix.end))
            .snapshotChanges()
            .pipe(map(items => items.map(i => ({ [i.payload.key]: i.payload.val() }))));
    }

    // Get any device from Database given it's IMEI (key)
    getDevice(deviceIMEI): Observable<IDevice> {
        return this.db.object(this.devicesRef + deviceIMEI).snapshotChanges()
            // If device exist, add the key and return the object, otherwise return null
            .pipe(map(c => c.payload.val() ? { key: c.payload.key, ...c.payload.val() as IDevice } : null));
    }

    // Top Notifications Alarms
    getAlarms(): BehaviorSubject<number> {
        return this._alarms;
    }

    setAlarms(newValue: number) {
        this._alarms.next(newValue);
    }

    dismissAlarm(deviceIMEI, alarmID) {
        return this.devicesList.remove(deviceIMEI + '/alarms/' + alarmID);
    }

    // Notifications
    setNotificationToken(userUID: string, token: string) {
        const tokenRef = userUID + '/notificationTokens/' + token;
        localStorage.setItem('tokenRef', tokenRef);
        return this.usersList.set(tokenRef, true);
    }

    removeLocalNotificationToken() {
        const tokenRef = localStorage.getItem('tokenRef');
        return tokenRef
            ? this.usersList.set(tokenRef, null).then(() => {
                localStorage.removeItem('tokenRef');
                localStorage.removeItem('notificationActivated');
            })
            : Promise.resolve();
    }

    //#endregion "User"

    //#region Device Control

    stopDevice(deviceKey: string) {
        return this.devicesList.update(deviceKey, {
            pump_status: 'off',
            is_pending: true,
        });
    }

    startDeviceInAutoMode(deviceKey: string, amountQuarts: number) {
        return this.devicesList.update(deviceKey, {
            auto_mode_quarts_day: amountQuarts,
            pump_mode: 'auto',
            pump_status: 'on',
            is_pending: true,
        });
    }

    startDeviceInAutoModeMP(deviceKey: string, amountQuarts: number[]) {
        return this.devicesList.update(deviceKey, {
            wellheads_auto_quarts: amountQuarts,
            pump_mode: 'auto',
            pump_status: 'on',
            is_pending: true,
        });
    }

    startDeviceInBatchMode(deviceKey: string) {
        return this.devicesList.update(deviceKey, {
            pump_mode: 'batch',
            pump_status: 'on',
            is_pending: true,
        });
    }

    startDeviceInPIDMode(deviceKey: string, pid_setpoint: number, pid_minspeed: number) {
        return this.devicesList.update(deviceKey, {
            pump_mode: 'pid',
            pump_status: 'on',
            is_pending: true,
            pid_setpoint: pid_setpoint,
            pid_minspeed: pid_minspeed,
        });
    }

    startDeviceInTimeMode(deviceKey: string, time_on_duration: number, time_off_duration: number) {
        return this.devicesList.update(deviceKey, {
            pump_mode: 'time',
            pump_status: 'on',
            is_pending: true,
            time_on_duration: time_on_duration,
            time_off_duration: time_off_duration,
        });
    }

    startDeviceInPropMode(deviceKey: string, prop_low_setpoint: number, prop_high_setpoint: number) {
        return this.devicesList.update(deviceKey, {
            pump_mode: 'prop',
            pump_status: 'on',
            is_pending: true,
            prop_low_setpoint: prop_low_setpoint,
            prop_high_setpoint: prop_high_setpoint,
        });
    }

    setDeviceConfigurations(deviceKey: string, newConf: {}) {
        // TODO Add Activity
        return this.devicesList.update(deviceKey, newConf);
    }

    saveDeviceNotes(deviceKey: string, notes: string) {
        return this.devicesList.update(deviceKey, { notes: notes });
    }

    deactivateDevice(deviceKey: string) {
        return this.devicesList.update(deviceKey, { is_activated: false });
    }

    deleteDevice(deviceKey: string) {
        return this.devicesList.remove(deviceKey);
    }

    //#endregion Device Control

    //#region Device Settings


    updateDeviceGeneralSettings(deviceKey: string, settings: any) {
        return this.devicesList.update(deviceKey + '/settings', settings);
    }

    setAsSinglePointDevice(deviceKey: string) {
        return this.devicesList.update(deviceKey, {
            is_multipoint: false,
            wellheads_names: null,
        });
    }

    setAsMultiPointDevice(deviceKey: string, wellHeads: string[]) {
        return this.devicesList.update(deviceKey, {
            is_multipoint: true,
            wellheads_names: wellHeads,
        });
    }

    // A function to signup for a user with email.
    requestDiagnosisSYNC(pumpIMEI: string) {

        // Add to FirebaseAuth then Database
        const url = environment.firebaseFunctionsURL + '/requestDiagnosisSYNC';
        return this.authService.getCurrentAuthUser()
            .then(currentUser => {
                return currentUser.getIdToken();
            })
            .then(authToken => {
                const headers = new HttpHeaders(
                    { 'Authorization': 'Bearer ' + authToken },
                );
                const body = { pumpIMEI: pumpIMEI };
                return this.http.post(url, body, { headers: headers }).toPromise();
            })
            .then(response => {
                return response;
            });
    }


    //#endregion

    //#region "Super Admin Functions"

    //#region "Company Management"

    getCompaniesList(): Observable<ICompany[]> {
        return this.companiesList.snapshotChanges().pipe(
            map((changes: any) =>
                changes.map(c => ({ key: c.payload.key, ...c.payload.val() })),
            ),
        );
    }

    addCompany(company: ICompany) {
        return this.companiesList.push(company);
    }

    updateCompany(newCompanyData: ICompany) {
        const key = newCompanyData.key;
        delete newCompanyData.key;
        return this.companiesList.update(key, newCompanyData);
    }

    deleteCompany(key: string) {
        return this.companiesList.remove(key);
    }

    //#endregion "Company Management"

    //#region "User Management"

    // Get All Users
    getAllUsersList(): Observable<IUser[]> {
        return this.usersList.snapshotChanges().pipe(
            map(changes => changes.map(c => ({
                key: c.payload.key,
                ...c.payload.val(),
            })),
            ),
        );
    }

    // Get All Company's users
    getCompanyUsersList(companyKey: string): Observable<IUser[]> {
        // Use snapshotChanges().map() to store the key
        return this.db.list(this.usersRef, ref => ref.orderByChild('companyKey').equalTo(companyKey))
            .snapshotChanges().pipe(
                map(changes => changes.map(c => ({
                    key: c.payload.key,
                    ...c.payload.val() as IUser,
                })),
                ),
            );
    }

    // Get All Company's devices
    getCompanyDevicesList(companyKey: string, forAssign?: boolean): Observable<IDevice[]> {
        // Use snapshotChanges().map() to store the key
        // For ADB-185: Allow any device to be assigned to a Super Admin (SIKEY Company users)
        let listRef: AngularFireList<unknown>;
        if (companyKey === 'SIKEY' && forAssign) {
            listRef = this.db.list(this.devicesRef, ref => ref.orderByChild('companyKey'));
        } else {
            listRef = this.db.list(this.devicesRef, ref => ref.orderByChild('companyKey').equalTo(companyKey));
        }
        return listRef.snapshotChanges().pipe(
            map(changes => changes.map(c => ({
                key: c.payload.key,
                ...c.payload.val() as IDevice,
            })),
            ),
        );
    }

    // A function to signup for a user with email.
    signUpWithEmail(user: IUser) {

        // Add to FirebaseAuth then Database
        const url = environment.firebaseFunctionsURL + '/createNewUser';
        return this.authService.getCurrentAuthUser()
            .then(currentUser => {
                return currentUser.getIdToken();
            })
            .then(authToken => {
                const headers = new HttpHeaders(
                    { 'Authorization': 'Bearer ' + authToken },
                );
                const body = { user: user };
                return this.http.post(url, body, { headers: headers }).toPromise();
            })
            .then(response => {
                return response;
            });
    }


    // Update user data
    updateUser(newUserData: IUser) {
        const key = newUserData.key;
        delete newUserData.key;
        // TODO make sure to reflect on Auth Profile and Claims using FF
        return this.usersList.update(key, newUserData);
    }

    deleteUser(userUID) {
        return this.usersList.remove(userUID);
    }

    //#endregion "User Management"

    //#region "Device Management"

    getAllDevicesList(): Observable<any[]> {
        return this.devicesList.snapshotChanges().pipe(
            map(changes => changes.map(c => ({
                key: c.payload.key,
                ...c.payload.val(),
            })),
            ),
        );
    }

    // function to add a device.
    addDevice(device) {
        // TODO add activity
        const key = device.deviceIMEI;
        delete device.deviceIMEI;
        return this.devicesList.set(key, device);
    }

    // function to assign a device to a user (and vice versa)
    assignDevice(deviceKey: string, userUID: string) {

        // TODO add activity

        // TODO handle with Firebase Functions
        return this.usersList.set(userUID + '/' + this.devicesRef + deviceKey, true).then(() => {
            return this.devicesList.set(deviceKey + '/' + this.usersRef + userUID, true);
        });
    }

    // function to release a device from a user (and vice versa)
    releaseDevice(deviceKey: string, userUID: string) {

        // TODO add activity

        // TODO handle with Firebase Functions
        return this.usersList.remove(userUID + '/' + this.devicesRef + deviceKey).then(() => {
            return this.devicesList.remove(deviceKey + '/' + this.usersRef + userUID);
        });
    }

    // function to edit a device name/company.
    updateDevice(device) {
        // TODO Add Activity
        return this.devicesList.update(device.key, {
            name: device.name,
            companyKey: device.companyKey,
            companyName: device.companyName,
        });
    }

    // function to edit a device name (the only thing Admin can change).
    updateDeviceName(device) {
        // TODO Add Activity
        return this.devicesList.update(device.key, {
            name: device.name,
        });
    }

    //#endregion "Device Management"

    // Get Company Activities
    getCompanyActivities(companyKey, dateRangeUnix) {
        return this.db.list(this.companyHistoryRef + companyKey,
            ref => ref.orderByKey().startAt(dateRangeUnix.start).endAt(dateRangeUnix.end))
            .snapshotChanges()
            .pipe(map(items => items.map(i => ({ [i.payload.key]: i.payload.val() }))));
    }

    //#endregion "Super Admin Functions"
}

import * as L from 'leaflet';

export const APP_VER = 'v8.1.2';

export const DEFAULT_DATE_RANGE_DAYS = 14;

export const DEFAULT_MIN_RANGE_MONTHS = 6;

export const NOTIFICATION_SNOOZE_DAYS = 7;

//#region Maps

export const MAP_POPUP_OFFSET = new L.Point(0, 100);

export const HERE_API_KEY = '2xfMCaEg1O4YrY6orp6hRmLXBf03hh7_Q-PYuZSKhFU';

export const THUNDERFOREST_APP_CODE = '93abf2c6eaaa481f896881a29f428672';

//#endregion

//#region Not Used

// export const FLOW_MODEL = ['Si125'];

// export const PLUNGER_SIZE = [
//     '1/8', '1/4', '3/8', '1/2', '3/4', '1',
// ];

// export const SEAL_TYPE = [
//     'Viton', 'Buna', 'Teflon', 'Tri-Seal', 'Kalrez', 'Neo-prene', 'Other',
// ];

// export const PUMP_HEAD = [
//     'Single', 'Dual',
// ];

// export const CONNECTION_TYPE = [
//     'Sat', 'Cell', 'Wifi',
// ];

//#endregion

import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';

import { LayoutService } from '../../../@core/utils';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AuthService } from '../../../services/auth.service';
import { FirebaseService } from '../../../services/firebase.service';

@Component({
    selector: 'ngx-header',
    styleUrls: ['./header.component.scss'],
    templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

    private destroy$: Subject<void> = new Subject<void>();
    userPictureOnly: boolean = false;
    user: any;
    isAdmin: boolean;
    isSuperAdmin: boolean;
    alarms: string;

    themes = [
        {
            value: 'default',
            name: 'Light',
        },
        {
            value: 'dark',
            name: 'Dark',
        },
        // {
        //   value: 'cosmic',
        //   name: 'Cosmic',
        // },
        {
            value: 'corporate',
            name: 'Corporate',
        },
    ];

    currentTheme = 'default';

    userMenu = [{ title: 'Profile' }, { title: 'Log out', link: '/auth/logout' }];

    constructor(private sidebarService: NbSidebarService,
        private menuService: NbMenuService,
        private themeService: NbThemeService,
        private authService: AuthService,
        private fireService: FirebaseService,
        private layoutService: LayoutService,
        private breakpointService: NbMediaBreakpointsService) {
    }

    ngOnInit() {
        this.currentTheme = this.themeService.currentTheme;

        const { xl } = this.breakpointService.getBreakpointsMap();
        this.themeService.onMediaQueryChange()
            .pipe(
                map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
                takeUntil(this.destroy$),
            )
            .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

        this.themeService.onThemeChange()
            .pipe(
                map(({ name }) => name),
                takeUntil(this.destroy$),
            )
            .subscribe(themeName => this.currentTheme = themeName);

        // Set user from Auth
        this.authService.getCurrentAuthUser().then(user => this.user = user);
        this.authService.isAdmin().then(isAdmin => this.isAdmin = isAdmin);
        this.authService.isSuperAdmin().then(isSuperAdmin => this.isSuperAdmin = isSuperAdmin);

        // Alarms Notifications Icon
        this.fireService.getAlarms().subscribe(alarms => this.alarms = alarms ? alarms.toString() : '');

        // Auto Collapse Menu
        const { sm } = this.breakpointService.getBreakpointsMap();
        this.menuService.onItemSelect()
            .pipe(takeUntil(this.destroy$))
            .subscribe((event: { tag: string, item: any }) => {
                if (document.documentElement.clientWidth < sm) {
                    this.sidebarService.collapse('menu-sidebar');
                }
            });
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    changeTheme(themeName: string) {
        this.themeService.changeTheme(themeName);
    }

    toggleSidebar(): boolean {
        this.sidebarService.toggle(true, 'menu-sidebar');
        this.layoutService.changeLayoutSize();

        return false;
    }

    navigateHome() {
        this.menuService.navigateHome();
        return false;
    }
}

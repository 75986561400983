import { Injectable, OnInit } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import firebase from 'firebase/app';


@Injectable({
    providedIn: 'root',
})
export class AuthService implements OnInit {

    constructor(
        private fireAuth: AngularFireAuth,
        private router: Router,
    ) {
        // TODO revisit why it is not working in ngOnInit
        // Make sure all pages sign out as soon as user signs out from anywhere
        this.getAuthUser().subscribe(user => {
            if (user === null) {
                // User is null, go to Login
                this.router.navigateByUrl('auth');
            }
        });
    }

    ngOnInit() {

    }

    getAuthUser(): Observable<firebase.User | null> {
        return this.fireAuth.authState;
    }

    async getHeadersWithIdToken() {
        const currentUser = await this.getCurrentAuthUser();
        const authToken = await currentUser.getIdToken();
        return new HttpHeaders(
            { 'Authorization': 'Bearer ' + authToken },
        );
    }

    getAuthUserPromise(): Promise<firebase.User | null> {
        return this.fireAuth.authState.pipe(first()).toPromise();
    }

    getCurrentAuthUser(): Promise<firebase.User> {
        return this.fireAuth.currentUser;
    }

    // Check if the currently logged in user has Admin Privileges
    isAdmin() {
        return this.getCurrentAuthUser().then(user => {
            if (!!user) return user.getIdTokenResult().then(idTokenResult => idTokenResult.claims.isAdmin);
            return false;
        });
    }

    // Check if the currently logged in user has Admin Privileges
    isSuperAdmin() {
        return this.getCurrentAuthUser().then(user => {
            if (!!user) return user.getIdTokenResult().then(idTokenResult => idTokenResult.claims.isSuperAdmin);
            return false;
        });
    }


    isEmailVerified() {
        return this.getCurrentAuthUser().then(user => {
            if (!!user) return user.emailVerified;
            return false;
        });
    }

    getAuthTime() {
        return this.getCurrentAuthUser().then(user => {
            if (!!user) return user.getIdTokenResult().then(idTokenResult => idTokenResult.authTime);
        });
    }

    // TODO: Check here that the user exists in DB and verified
    signInWithGoogle() {
        return this.fireAuth.signInWithPopup(new firebase.auth.GoogleAuthProvider());
    }

    // TODO: Check here that the user exists in DB and verified
    signInWithEmailAndPassword(user: any) {
        return this.fireAuth.signInWithEmailAndPassword(user.email, user.password);
    }

    // send Verification Email to currently logged in user
    sendVerificationEmail() {
        return this.getCurrentAuthUser().then(user => {
            return user.sendEmailVerification();
        });
    }

    // send Reset Password Email to user with given email
    sendPasswordResetEmail(email: string) {
        return this.fireAuth.sendPasswordResetEmail(email);
    }

    // Sign out currently logged in user
    signOut() {
        return this.fireAuth.signOut();
    }

}

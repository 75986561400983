import { Component } from '@angular/core';

@Component({
    selector: 'ngx-footer',
    styleUrls: ['./footer.component.scss'],
    template: `
    <span class="created-by">
      Created with ♥ by <b> <a href="https://simpleinjection.com/" target="_blank">Simple Injection™</a></b> {{ year }}
    </span>
    <div class="socials">
      <a href="https://www.linkedin.com/company/simple-injection/" target="_blank" class="ion ion-social-linkedin"></a>
    </div>
  `,
})
export class FooterComponent {
    year = new Date().getFullYear();
}

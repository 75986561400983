import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import {
    AngularFireAuthGuard,
    customClaims,
    hasCustomClaim,
    redirectUnauthorizedTo,
    redirectLoggedInTo,
} from '@angular/fire/auth-guard';

import { map } from 'rxjs/operators';
import { pipe } from 'rxjs';

const verifiedOnlyRedirect = () => pipe(customClaims, map(
    claims => claims.email_verified ? true : ['auth/request-verification'],
));
const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['auth']);

export const routes: Routes = [
    {
        path: 'pages',
        loadChildren: () => import('./pages/pages.module')
            .then(m => m.PagesModule),
        canActivate: [AngularFireAuthGuard],
        data: { authGuardPipe: verifiedOnlyRedirect },
    },
    {
        path: 'auth',
        loadChildren: () => import('./auth/auth.module')
            .then(m => m.NgxAuthModule),
    },
    {
        path: '',
        redirectTo: 'pages',
        pathMatch: 'full',
    },
    {
        path: '**',
        redirectTo: 'pages',
    },
];

const config: ExtraOptions = {
    useHash: false,
};

@NgModule({
    imports: [RouterModule.forRoot(routes, config)],
    exports: [RouterModule],
})
export class AppRoutingModule { }
